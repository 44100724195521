<template>
  <div class="d-flex align-items-center min-vh-100 login-box">
    <b-container>
      <b-row class="justify-content-center">
        <c-col md="9" lg="6" class="login">
          <b-card class="p-4 card">
            <b-form v-on:submit.prevent="checkForm">
              <div class="text-center">
                <h1>ADMIN LOGIN</h1>
              </div>

              <InputText
                class="my-4 login-input"
                v-model="form.Email"
                textFloat="Email"
                placeholder="Email"
                type="email"
                name="email"
                isRequired
                :isValidate="$v.form.Email.$error"
                :v="$v.form.email"
                @onKeyup="submitFormOnInput"
              />
              <InputText
                class="my-4 login-input"
                v-model="form.Password"
                textFloat="Password"
                placeholder="Password"
                type="password"
                name="password"
                isRequired
                :isValidate="$v.form.Password.$error"
                :v="$v.form.password"
                @onKeyup="submitFormOnInput"
              />
              <div class="text-center">
                <p class="text-danger">{{ error }}</p>
              </div>
              <b-row>
                <c-col class="text-center">
                  <b-button
                    type="button"
                    class="px-4 login-btn"
                    @click="checkForm"
                    :disabled="isDisable"
                    >LOGIN</b-button
                  >
                </c-col>
              </b-row>
            </b-form>
          </b-card>
        </c-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";

export default {
  name: "Login",
  components: {
    InputText,
  },
  data() {
    return {
      error: "",
      form: {
        Email: "",
        Password: "",
      },
      isLoading: false,
      isDisable: false,
    };
  },
  validations() {
    return {
      form: {
        Email: { required, email },
        Password: { required, minLength: minLength(6) },
      },
    };
  },
  beforeCreate() {
    if (this.$cookies.get("back_office_admin_sale_order_token")) {
      window.location.href = "/welcome";
    }
  },
  methods: {
    submitFormOnInput: function (e) {
      if (e.keyCode === 13) {
        this.checkForm();
      }
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.isDisable = true;
      this.$EventBus.$emit("showLoading");
      await this.$store.dispatch("ActionLogin", this.form);
      var data = await this.$store.state.moduleConnectAPI.stateLogin;
      this.isDisable = false;

      if (data.result == 1) {
        this.error = "";
        this.$cookies.set("back_office_admin_sale_order_token", data.detail.token);
        this.$EventBus.$emit("hideLoading");
        window.location.href = this.$route.query.nextUrl || "/welcome";
      } else {
        this.$swal2.fire(data.message, "", "error");
        this.$EventBus.$emit("hideLoading");
        this.error = data.message;
      }
    },
  },
};
</script>

<style scoped>
.login-box {
  background-color: var(--primary-color);
}
.card {
  width: 100%;
  background-color: #ffffff;
  border: transparent solid 1px;
  /* border-top-left-radius: 20%; */
  /* border-bottom-right-radius: 20%; */
  box-shadow: 0px 4px 15px -3px #7b7b7b;
}

h1 {
  color: var(--primary-color);
}
.login-btn {
  padding: 8px !important;
  font-size: 15px;
  border-radius: 10px !important;
  background: #ffffff;
  border: var(--primary-color) solid 1px;
  color: var(--primary-color);
  text-align: center;
  width: 25%;
  margin: auto;
  transition: 0.3s;
}
.login-btn:hover {
  /* color: var(--primary-color); */
  background-color: var(--primary-color);
  color: #ffffff;
}
.login {
  max-width: 500px;
}

@media (max-width: 600px) {
  .card-body {
    padding: 1.25rem 0;
  }
  .login-btn {
    width: 100% !important;
  }
}
</style>
